import React from "react";

const PageBuilderMap = ({block}) => 
{const {office} = block
  return (
    <div className="map_section mb-8">
      <div id="map">
        <iframe
          title="template google map"
          src={office.googleMap}
          allowFullScreen
          className="rounded-lg"
        ></iframe>
      </div>
    </div>
  );
};

export default PageBuilderMap;
