import React from 'react'

const PageBuilder3ImageBanner = ({block, raw}) => {
    const {title, text1, text2, image1, image2, image3} = block

  return (
    <section className="about_style_3_area  mx-auto md:flex py-4 md:py-8">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <div className="about_image_box rounded-lg" style={{ backgroundImage: `url(${image1.asset.url})`, backgroundPosition: 'center' }}></div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="about_image_box about_image_box2 rounded-lg" style={{ backgroundImage: `url(${image2.asset.url})`, backgroundPosition: 'center' }}></div>
          </div>
          <div className="col-md-5 col-sm-6">
            <div className="about_image_box about_image_box3 rounded-lg" style={{ backgroundImage: `url(${image3.asset.url})`, backgroundPosition: 'center' }}></div>
          </div>
          {title && 
            <div className="col-md-4 col-sm-6">
              <div className="about_style_3_details">
                <h1>{title}</h1>
              </div> 
            </div>
          }
          {text1 && 
            <div className="col-md-4 col-sm-6">
              <div className="about_style_3_details">
                <p>{text1}</p>
              </div>
            </div>
          }
          {text2 &&
          <div className="col-md-4 col-sm-6">
            <div className="about_style_3_details">
              <p>{text2}</p>
            </div>
          </div>
          }
        </div>
      </div>
    </section>
  )
}

export default PageBuilder3ImageBanner
