import React, { Fragment, useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";
import getYouTubeId from 'get-youtube-id'


const PageBuilderVideo = ({block, url}) => {
    const {title, video, image} = block
    const [isOpen, setOpen] = useState(false);
    return (
      <Fragment>
        <section className="my-8 video_promotion_area rounded-lg text-center" style={{ backgroundImage: `url(${image? image.asset.url : '../../breadcrumb.jpg'})`, backgroundPosition: 'center' }}>
          <div className="overlay rounded-lg">
            <a
              onClick={(e) => {
                e.preventDefault();
                setOpen(true);
              }}
              href={url}
              className="video-img"
              title="Play Icon"
              id="videolink"
            >
              <span className="video-play">
                <i className="fa fa-play"></i>
              </span>
            </a>
            <h1>{title}</h1>
          </div>
        </section>
        {typeof window !== "undefined" ? (
          <ModalVideo
            channel="youtube"
            autoplay
            ratio="16:9"
            isOpen={isOpen}
            videoId={getYouTubeId(video.url)}
            onClose={() => setOpen(false)}
          />
        ) : null}
      </Fragment>
  )
}

export default PageBuilderVideo
