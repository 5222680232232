import React from "react";
import { useForm } from "react-hook-form";

const PageBuilderContact = ({ block }) => {
  const { description, tagline, title } = block;
  const { register, handleSubmit, errors, reset } = useForm();
  const handlePost = (formData) => {};

  return (
    <section className="contact_form_area contact_us section_padding">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="contact-right contact_details">
              <div className="hero-title-with-shape">
                <h4 className="heading_with_border">{tagline}</h4>
                <h1>{title}</h1>
              </div>
              <p>{description}</p>
              <div className="contact_location_box">
                <div className="contact_location_map contact_location_call">
                  <span className="icon-contact_call"></span>
                  <p>
                    <a href="mailto:info@ferrarafirm.com">
                      info@ferrarafirm.com
                    </a>{" "}
                    <br />
                    <a href="tel:315-437-7600">315-437-7600</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="contact-right contact-right-style-2 responsive_mt">
              <form
                method="post"
                netlify-honeypot="bot-field"
                data-netlify="true"
                name="Contact"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="Contact" />
                <input
                  className="half_width input_m_right"
                  type="text"
                  name="name"
                  required
                  placeholder="Your name"
                />
                <input
                  className="half_width"
                  type="EMAIL"
                  name="email"
                  required
                  placeholder="Email address"
                />
                <input type="tel" name="tel" placeholder="Phone number" />
                <textarea
                  name="content"
                  id="content"
                  cols="20"
                  rows="5"
                  placeholder="Write message"
                ></textarea>
                <button className="btn-yellow" value="SUBMIT">
                  SUBMIT
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageBuilderContact;
