import React from 'react'
import PortableTextBlock from './portableText'
import imageUrlBuilder from '@sanity/image-url'
import clientConfig from '../../client-config'


const PageBuilderContent = ({block, raw}) => {
  const {title, image} = block

  const builder = imageUrlBuilder(clientConfig.sanity)
  function urlFor(source) {
    return builder.image(source)
  }

  return (
    <section className='container mx-auto  py-4 md:py-8'>
        {image && image.asset && image.asset.url && (
          <>
            <img src={urlFor(image).width(1920).height(800).url()} alt="feature" className="img-fluid rounded-lg mb-4"/>
            <div className='w-full rounded-r-lg'>
            <h1 className='text-4xl leading-none font-bold my-4'>
              {title}
            </h1>
            <PortableTextBlock blocks={raw.body} />
          </div>
        </>
        )}

        {image == null  && (
          <div className='w-full rounded-lg'>
            <h1 className='text-4xl leading-none font-bold mb-4'>
              {title}
            </h1>
            <PortableTextBlock blocks={raw.body} />
          </div>
        )}
    </section>
  )
}

export default PageBuilderContent
