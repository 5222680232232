import React from 'react'
import { Col, Container, Row } from "react-bootstrap";


const PageBuilderContent = ({block}) => {
  const {title,image ,tagline, caption,lists} = block

  return (
    <section className="about_service_area section_padding">
    <Container>
    {image && image.asset && image.asset.url && (
      <Row>
        <Col lg={6}> 
          <div className="about_service_left min-h-[300px] rounded-lg h-full" style={{ backgroundImage: `url(${image.asset.url})`, backgroundPosition: 'center' }}>
            <p className="about_service_year rounded-lg text-center">
              <span>{caption}</span>
            </p>
          </div>
        </Col>
        <Col lg={6}>
          <div className="about_service_right">
            <div className="hero-title-with-shape">
              <h4 className="heading_with_border">{tagline}</h4>
              <h1>{title}</h1>
            </div>
            <ul>
              {lists.map((list , index) => (
                <li key={index}>
                  <i className="fa fa-check-circle"></i> {list}
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
       )} {image == null  && ( 
        <Row className='lg:flex'>
          <Col lg={7} className="hero-title-with-shape bg-gray-500 text-white rounded-lg p-6">
          <div >
              <h4 className="heading_with_border text-white">{tagline}</h4>
              <h1 className='text-white'>{title}</h1>
            </div>
          </Col>
          <Col lg={5} className="text-xl">
            <ul>
              {lists.map((list , index) => (
                <li className='py-2 text-gray-500' key={index}>
                  <i className="fa text-gray-900 fa-check-circle"></i> {list}
                </li>
              ))}
            </ul>
          </Col>
      </Row>
       )}
    </Container>
  </section>
  )
}

export default PageBuilderContent
