import React from 'react'

import PortableTextBlock from './portableText'

const PageBuilderColumns = ({block, raw}) => {
  const {columns} = block
  const length = columns.length;

  return (
    <section className='bg-gray-100 rounded-lg'>
      <div className='container mx-auto md:flex py-4'>
      {length === 2 && columns.map((column, index) =>
          <div key={index} className='md:w-1/2 p-4'>
            <div className='bg-white rounded-lg p-4'>
              <h2 className='text-2xl text-gray-600 font-bold mb-5'>
                {column.title}
              </h2>
              <PortableTextBlock blocks={raw.columns[index].body} />
            </div>
          </div>
        )}
        {length > 2 && columns.map((column, index) =>
          <div  key={index} className='md:w-1/3 p-4'>
            <div className='bg-white rounded-lg p-4'>
              <h2 className='text-2xl text-gray-600 font-bold mb-5'>
                {column.title}
              </h2>
              <PortableTextBlock blocks={raw.columns[index].body} />
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default PageBuilderColumns
