import React from "react";
import Link from "@/components/link";

const PageBuilderCTABanner = ({block}) => 
{const {title, cta, link} = block
  return (
        <section className="call_to_action my-4">
            <div className="container">
            <div className="row">
                <div className="col-md-9 col-sm-8 col-xs-12">
                <h1>{title}</h1>
                </div>
                <div className="col-md-3 col-sm-4 col-xs-12 text-end cta_responsive_left">
                <Link href={link.slug} className="btn-gray">
                    {cta}
                </Link>
                </div>
            </div>
            </div>
        </section>
  );
};

export default PageBuilderCTABanner;
