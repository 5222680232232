import React from 'react'
import PageBuilderContent from './pageBuilderContent'
import PageBuilderColumns from './pageBuilderColumns'
import PageBuilder3ImageBanner from './pageBuilder3ImageBanner'
import PageBuilderVideo from './pageBuilderVideo'
import PageBuilderList from './pageBuilderList'
import PageBuilderMap from './pageBuilderMap'
import PageBuilderCTABanner from './pageBuilderCTABanner'
import PageBuilderContact from './pageBuilderContact'
import PageBuilderAccordion from './pageBuilderAccordion'



function PageBuilder (props) {
  const {type, pageBuilder, _rawPageBuilder} = props

  // Load the right component, based on the _type from Sanity
  const Components = {
    pageBuilderContent: PageBuilderContent,
    pageBuilder3ImageBanner: PageBuilder3ImageBanner,
    pageBuilderColumns: PageBuilderColumns,
    pageBuilderVideo: PageBuilderVideo,
    pageBuilderList: PageBuilderList,
    pageBuilderMap: PageBuilderMap,
    pageBuilderCTABanner: PageBuilderCTABanner,
    pageBuilderContact: PageBuilderContact,
    pageBuilderAccordion: PageBuilderAccordion,

  }

  // 'raw' content needs to be passed in for the PortableText Component
  return pageBuilder.map((block, index) => {

    if (Components[block._type]) {
      return React.createElement(Components[block._type], {
        key: block._key,
        block: block,
        type,
        raw: _rawPageBuilder[index]
      })
    }
  })
}

export default PageBuilder
