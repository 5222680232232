import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import PortableTextBlock from './portableText'



const PageBuilderAccordion = ({block}) => {
  const {title,about,bullets} = block

  return (
    <section className="container mx-auto  py-4 md:py-8">
        {title && <h1 className="text-4xl leading-none font-bold mb-4">{title}</h1>}
        {about && <p className="mb-4">{about}</p>}
        <Accordion>
            {bullets.map((list , index) => (
                <AccordionItem key={index}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        { list.heading}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {/* <p>{list.content}</p> */}
                        <PortableTextBlock blocks={list._rawContentRich}/>
                    </AccordionItemPanel>
                </AccordionItem>
            ))}
        </Accordion>
    </section>
  )
}

export default PageBuilderAccordion
