import React from 'react'
import {graphql} from 'gatsby'
import MenuContextProvider from "@/context/menu-context";
import Layout from '@/components/layout'
import HeaderThree from "@/components/header-one";
import PageBuilder from '@/components/pageBuilder'
import {Container } from "react-bootstrap";
import Footer from "@/components/footer";
import PageHeader from "@/components/page-header";
import Ferrara13 from "@/images/ferrara/ferrara_13.jpg";



export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: {eq: $id}) {
      id
      ...PageBuilder
      title
      mainImage {
        asset {
          url
        }
      }
      slug {
        current
      }
    }
  }
`

const PageTemplate = props => {
  const {data} = props
  const page = data && data.page
  const {pageBuilder, _rawPageBuilder} = page

  return (
    <MenuContextProvider>
        <Layout PageTitle={page.title}>
        <HeaderThree />
        <PageHeader title={page.title} image={page.mainImage !== null? page.mainImage.asset.url : Ferrara13} name={page.title} />
        <Container>
            <PageBuilder pageBuilder={pageBuilder} _rawPageBuilder={_rawPageBuilder} />
        </Container>
        <Footer />
        </Layout>
    </MenuContextProvider>
  )
}

export default PageTemplate
